<template>
  <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
    Loading class list
  </loading-spinner>
  <school-details v-else-if="currentSchool" :currentSchool="currentSchool" @update="updateSchool" @close="viewSchool" />
  <div v-else>
    <div class="pb-3 d-flex justify-content-between align-items-center">
      <div class="input-group">
        <span class="input-group-text bg-white">
          <i class="fas fa-search" />
        </span>
        <input type="text" class="form-control border-start-0 ps-0 no-focus"
          :class="{ 'border-end-0': search.length > 0 }" placeholder="Cari sekolah" v-model="search" />
        <button class="btn bg-white border border-light" type="button" v-if="search.length > 0" @click="search = ''">
          &times;
        </button>
      </div>
    </div>
    <div class="
          pb-3
          d-flex
          justify-content-between
          align-items-center
          flex-column flex-sm-row
        ">
      <div>
        <span class="badge bg-dark shadow-sm py-2 fw-normal">
          Jumlah Sekolah: {{ schools.length }}
        </span>
      </div>
    </div>
    <div class="d-flex flex-row-reverse">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click="prevPage">
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber"
            :class="{ 'active': currentPage === pageNumber }">
            <a class="page-link" href="#" @click="goToPage(pageNumber)">{{ pageNumber }}</a>
          </li>
          <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click="nextPage">
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="
          table-responsive
          rounded
          shadow-sm
          rounded
          border border-light
          position-relative
        ">
      <table class="table lh-sm table-admin w-100">
        <thead>
          <tr>
            <th class="pe-0">Nama</th>
            <th class="text-center">Kelas</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="cls in displayedData" :key="cls._id">
            <td class="text-nowrap text-truncate pe-0">
              <button class="
                    btn btn-link
                    p-0
                    w-100
                    lh-sm
                    clickable
                    text-start text-decoration-none text-truncate
                  " :title="cls.name" v-html="cls.name" @click="viewSchool(cls._id)"></button>
            </td>
            <td class="text-center mx-1" style="width: 1%">
              <span class="badge bg-light border text-dark fw-normal p-2">
                {{ cls.classes ? cls.classes.length : 0 }}
              </span>
            </td>
          </tr>
          <tr v-if="computedSchools.length < 1">
            <td colspan="8" class="text-center fst-italic text-muted">
              No class found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-4 d-flex flex-row-reverse">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click="prevPage">
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber"
            :class="{ 'active': currentPage === pageNumber }">
            <a class="page-link" href="#" @click="goToPage(pageNumber)">{{ pageNumber }}</a>
          </li>
          <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click="nextPage">
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
  
<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import SchoolDetails from "@/components/SchoolDetails";

export default {
  components: { LoadingSpinner, SchoolDetails },
  data() {
    return {
      isLoading: false,
      schools: [],
      search: "",
      currentMedia: null,
      currentSchool: null,
      currentClass: null,
      showClassModal: false,
      filterStatus: null,
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  computed: {
    computedSchools() {
      const search = this.search.toLowerCase();

      let result = [...this.schools];

      if (this.filterStatus) {
        result = result.filter(
          (enquiry) => enquiry.status == this.filterStatus
        );
      }

      return result.filter((enquiry) =>
        enquiry.name.toLowerCase().includes(search)
      );
    },
    totalPages() {
      return Math.ceil(this.schools.length / this.itemsPerPage);
    },
    displayedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      if (this.search != '') {
        return this.computedSchools
      } else {
        return this.schools.slice(startIndex, endIndex);
      }
    }
  },
  methods: {
    goToPage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
        this.search = '';
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.search = '';
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.search = '';
      }
    },
    updateSchool(e) {
      let school = this.schools.find((d) => d._id == e._id);
      let schoolIndex = this.schools.findIndex((d) => d._id == e._id);

      if (school) {
        this.$set(this.schools, schoolIndex, e);

        this.handleParamChange();
      }
    },
    viewSchool(schoolId) {
      let route = {
        name: this.$route.name,
        params: {
          id: schoolId,
        },
        query: {},
      };

      this.$router.push(route);
    },
    handleParamChange() {
      if (this.$route.params.id) {
        this.currentSchool = this.schools.find(
          (school) => school._id == this.$route.params.id
        );
      } else {
        this.currentSchool = null;
      }
    },
    getSchools() {
      this.isLoading = true;

      return this.API.get("schools?_limit=-1")
        .then((retVal) => {
          this.schools = retVal.data;
        })
        .catch((err) => { })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    let waitUserAvailable = async () => {
      this.isLoading = true;

      if (this.$store.state.user) {
        await this.getSchools();

        this.isLoading = false;

        this.handleParamChange();
      } else {
        setTimeout(() => {
          waitUserAvailable();
        }, 1000);
      }
    };

    waitUserAvailable();
  },
};
</script>