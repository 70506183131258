<template>
  <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
    Loading school details
  </loading-spinner>
  <class-details v-else-if="currentClass" :currentClass="currentClass" @update="updateClass" @close="viewClass" />
  <div v-else-if="!isLoading">
    <div class="p-3 bg-blue-dark text-light rounded shadow-sm border d-flex align-items-center">
      <button class="btn btn-sm py-0 me-3 lh-1" @click="$emit('close')" v-if="!$store.state.user.isStudent">
        <i class="fas fa-arrow-left text-light" />
      </button>
      <p class="mb-0 text-truncate fw-medium lh-1" v-if="currentSchool">
        {{ currentSchool.name }}
      </p>
      <span class="text-muted fst-italic" v-else>Tiada sekolah dipilih</span>
    </div>
    <div class="py-3 text-nowrap overflow-auto no-scrollbar" v-if="currentSchool">
      <div class="pb-3 d-flex justify-content-between align-items-center">
        <div class="input-group">
          <span class="input-group-text bg-white">
            <i class="fas fa-search" />
          </span>
          <input type="text" class="form-control border-start-0 ps-0 no-focus"
            :class="{ 'border-end-0': search.length > 0 }" placeholder="Cari kelas" v-model="search" />
          <button class="btn bg-white border border-light" type="button" v-if="search.length > 0" @click="search = ''">
            &times;
          </button>
        </div>
      </div>
      <div class="
          table-responsive
          rounded
          shadow-sm
          rounded
          border border-light
          position-relative
        ">
        <table class="table lh-sm table-admin w-100">
          <thead>
            <tr>
              <th class="pe-0">Nama Kelas</th>
              <th class="text-center">Murid</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cls in computedClasses" :key="cls._id">
              <td class="text-nowrap text-truncate pe-0">
                <button class="
                    btn btn-link
                    p-0
                    w-100
                    lh-sm
                    clickable
                    text-start text-decoration-none text-truncate
                  " :title="cls.name" v-html="cls.name" @click="viewClass(cls._id)"></button>
              </td>
              <td class="text-center mx-1" style="width: 1%">
                <span class="badge bg-light border text-dark fw-normal p-2">
                  {{ cls.dependants ? cls.dependants.length : 0 }}
                </span>
              </td>
            </tr>
            <tr v-if="computedClasses.length < 1">
              <td colspan="8" class="text-center fst-italic text-muted">
                No class found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
  
<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import ClassDetails from "@/components/ClassDetails";

export default {
  props: ["currentSchool"],
  components: {
    LoadingSpinner,
    ClassDetails
  },
  data() {
    return {
      isLoading: false,
      currentMedia: null,
      currentClass: null,
      classes: [],
      search: "",
    };
  },
  watch: {
    currentSchool: {
      async handler() {
        if (this.currentSchool) {
          if (
            this.currentSchool.classes &&
            this.currentSchool.classes.length > 0
          ) {
            await this.getClasses();
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    computedClasses() {
      const search = this.search.toLowerCase();

      let result = [...this.classes];

      if (this.filterStatus) {
        result = result.filter(
          (enquiry) => enquiry.status == this.filterStatus
        );
      }

      return result.filter((enquiry) =>
        enquiry.name.toLowerCase().includes(search)
      );
    },
    className() {
      return (classId) => {
        const cls = this.classes.find((cls) => cls._id == classId);

        if (cls) {
          return cls.name;
        } else {
          return null;
        }
      };
    },
  },
  methods: {
    updateClass(e) {
      let kelas = this.classes.find((d) => d._id == e._id);
      let classIndex = this.classes.findIndex((d) => d._id == e._id);

      if (kelas) {
        this.$set(this.classes, classIndex, e);

        this.handleParamChange();
      }
    },
    viewClass(classId) {
      let route = {
        name: 'class-details',
        params: {
          classId: classId,
        },
        query: {},
      };

      this.$router.push(route);
    },
    handleParamChange() {
      if (this.$route.params.classId) {
        this.currentClass = this.classes.find(
          (kelas) => kelas._id == this.$route.params.id
        );
      } else {
        this.currentClass = null;
      }
    },
    async getClasses() {
      this.isLoading = true;

      return new Promise(async (resolve) => {
        try {
          const { data } = await this.API.get(
            `classes?_limit=-1&school=${this.currentSchool._id}`
          );

          this.classes = data;
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;

          resolve();
        }
      });
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.view) {
      this.currentView = this.$route.query.view;
    }
  },
};
</script>